<template>
    <div>
        <!-- 头部导航栏 -->
        <top-bar/>
        <!--内容页-->
        <div class="body">
            <!--播放器主体-->
            <div class="content">
                <el-row>
                    <el-col :span="18">
                        <!--视频播放器-->
                        <div>
                            <video id="player-container-id"
                                   preload="auto"
                                   playsinline
                                   webkit-playsinline
                                   height="550"
                                   style="width: 100%;"
                            />
                        </div>
                        <div class="title">
                            {{ data.name }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <!--课程目录及课程资料-->
                        <div class="course-info">
                            <!--标题-->
                            <div class="info-title">
                                <el-button class="button" :class="{active: currentModule === 0}"
                                           @click="currentModule = 0">课程目录
                                </el-button>
                                <el-button class="button" :class="{active: currentModule === 1}"
                                           @click="currentModule = 1">课程资料
                                </el-button>
                            </div>
                            <!--内容区域-->
                            <el-scrollbar ref="chatList" style="height: 90%">
                                <!-- 课程目录 -->
                                <div v-if="currentModule === 0" class="info-content">
                                    <div v-for="(item, key) in courseList"
                                         :key="key"
                                         class="module-info"
                                         @click="openCourse(key)"
                                    >
                                        <!--名称-->
                                        <div class="module">
                                        <span class="name">
                                            {{ item.whCourseModule.name }}
                                        </span>
                                            <!--<i class="el-icon-arrow-down" style="font-weight: bold"></i>-->
                                            <i :class="openKey.includes(key) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                                               style="font-weight: bold"></i>
                                        </div>
                                        <!--具体课程 -->
                                        <div v-if="openKey.includes(key)" class="infos">
                                            <!--课程信息-->
                                            <el-row v-for="(content, key1) in item.whCourseModuleContentList"
                                                    :key="key1"
                                                    class="infos-card"
                                                    @click.native.stop="playVideo(content, item.contentList[key1])"
                                            >
                                                <el-col :span="21">
                                                    <!--名称-->
                                                    <div class="name"
                                                         :class="{'is-playing-color': content.id === playId}">
                                                        {{ content.name }}
                                                    </div>
                                                    <!--    信息-->
                                                    <div class="info">
                                                        <i class="el-icon-video-play" style="font-size: 14px;"></i>
                                                        {{ timeFormat(item.contentList[key1].videoDuration) }}
                                                        <span v-if="content.id === playId" class="is-playing-tips">
                                                        正在播放
                                                    </span>
                                                    </div>
                                                </el-col>
                                                <el-col v-if="content.id === playId" :span="3"
                                                        style="text-align: right">
                                                    <!-- 图标 -->
                                                    <svg-icon name="playing" style="font-size: 16px;"/>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </div>
                                </div>
                                <!--课程资料-->
                                <div v-else class="info-content">
                                    <div v-for="(item, key) in fileList"
                                         :key="key"
                                         @click="downLoadFile(item.fileUrl)"
                                         class="module-info">
                                        <!--名称-->
                                        <div class="module">
                                        <span class="name">
                                            {{ item.titleName }}
                                        </span>
                                            <!--<i class="el-icon-arrow-down" style="font-weight: bold"></i>-->
                                            <i class="el-icon-download" style="font-weight: bold"></i>
                                        </div>
                                    </div>
                                </div>
                            </el-scrollbar>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--    教师信息-->
        <teacher-card :item="teacher"/>
    </div>
</template>

<script>

import TopBar from "@/layout/TopBar";
import TeacherCard from "../components/teacherCard";

export default {
    name: "index",
    components: {
        TeacherCard,
        TopBar,
    },
    data() {
        return {
            player: null,
            currentModule: 0,
            data: {},
            courseList: [],
            showPopup: false,
            tabsIndex: 0,
            teacher: {},
            playId: '',
            fileList: [],
            tempContent: {},
            isDownLoading: false,
            progress: 0,
            timeAll: 0,
            lastModuleContent: {},
            record: {},
            openKey: []
        }
    },
    mounted() {
        if (this.$route.params.id) {
            // 获取数据
            this.$api.get('/course/index/getCourseDetail', {
                params: {
                    id: this.$route.params.id
                }
            }).then(res => {
                this.data = res.data
                this.courseList = res.content
                this.teacher = res.teacher
                this.fileList = res.fileList
                this.record = res.record
                // 自动播放第一个
                if (res.record) {
                    // 说明有播放记录
                    for (var i = 0; i < this.courseList.length; i++) {
                        if (this.courseList[i].whCourseModule.id === res.record.moduleId) {
                            for (var j = 0; j < this.courseList[i].whCourseModuleContentList.length; j++) {
                                if (this.courseList[i].whCourseModuleContentList[j].id === res.record.moduleContentId) {
                                    this.openKey.push(i)
                                    this.playVideo(
                                        this.courseList[i].whCourseModuleContentList[j],
                                        this.courseList[i].contentList[j],
                                        res.record.videoStudyTime
                                    )
                                }
                            }
                        }
                    }
                } else {
                    // 没有播放记录，默认播放第一个
                    if (this.courseList.length) {
                        if (this.courseList[0].whCourseModuleContentList.length) {
                            this.playVideo(this.courseList[0].whCourseModuleContentList[0], this.courseList[0]
                                .contentList[0])
                        }
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        } else {
            this.$router.go(-1)
        }
    },
    beforeDestroy() {
        if (this.player) {
            // 更新时间
            this.watchVideoTimeUpdate()
            // 销毁播放器
            this.player.dispose()
        }
    },
    methods: {
        // 播放视频内容
        playVideo(moduleContent, video, seek) {
            // 更新听课时长
            this.watchVideoTimeUpdate()
            this.lastModuleContent = moduleContent
            // 标记播放内容
            this.playId = moduleContent.id
            if (this.player) {
                // 切换视频
                this.player.loadVideoByID({
                    fileID: video.videoId,
                    appID: '1307961979'
                })
            } else {
                // 需要初始化
                this.player = window.TCPlayer('player-container-id', {
                    fileID: video.videoId,
                    appID: '1307961979',
                    autoplay: true,
                    plugins: {
                        ProgressMarker: true
                    }
                }); // player-container-id 为播放器容器 ID，必须与 html 中一致
            }
            if (seek) {
                // 跳转指定时间
                this.player.currentTime(seek)
            }
        },
        // 更新听课记录
        watchVideoTimeUpdate() {
            if (this.lastModuleContent.id) {
                // 说明上次听课过
                let data = {
                    contentId: this.data.id,
                    moduleId: this.lastModuleContent.moduleId,
                    moduleContentId: this.lastModuleContent.id,
                    videoStudyTime: parseInt(this.player.currentTime()),
                    contentType: 0
                }
                this.$api.post('/course/index/saveWatchCourseInfo', data).then(res => {
                    console.log('听课时长保存成功', res)
                }).catch(err => {
                    console.log('听课视频记录失败', err)
                })
            }
        },
        // 格式化时间
        timeFormat(result) {
            var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
            var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
                60 % 60));
            var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
            if (h === '00') {
                return m + ":" + s;
            } else {
                return h + ":" + m + ":" + s;
            }
        },
        // 折叠打开课程
        openCourse(key) {
            let index = this.openKey.indexOf(key)
            if (index === -1) {
                // 新增
                this.openKey.push(key)
            } else {
                this.openKey.splice(index, 1)
            }
        },
        // 点击查看文件
        downLoadFile(url) {
            window.open(url)
        }
    }

}
</script>

<style scoped lang="scss">
.body {
    width: 100%;
    background: #222736;
    padding-top: 130px;

    .content {
        width: 1200px;
        margin: 0 auto;
        height: 650px;

        .title {
            margin-top: 15px;
            margin-bottom: 5px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
        }

        .course-info {
            background: #1d202b;
            height: 630px;
            padding: 10px;

            .info-title {
                padding-top: 20px;
                text-align: center;

                .button {
                    padding: 5px 8px;
                    margin: 0 10px;
                    width: 82px;
                    border-radius: 2px;
                    border: 1px solid transparent;
                    font-size: 16px;
                    font-weight: 500;
                    background: transparent;
                    color: #fff;
                }

                .active {
                    background: $sr-main-color;
                }
            }

            .info-content {
                margin-top: 20px;

                .module-info {
                    .module {
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        height: 44px;
                        padding: 10px;
                        margin-bottom: 10px;
                        border-radius: 5px;
                        line-height: 24px;
                        background: #484d53;
                        color: #fff;
                        cursor: pointer;

                        .name {
                            @include text-overflow(1);
                            font-size: 14px;
                            flex: 1;
                            white-space: nowrap;
                        }
                    }

                    .infos {
                        padding: 10px 20px;
                        color: hsla(0, 0%, 100%, .7);

                        .infos-card {

                            cursor: pointer;
                            padding: 12px 0;

                            &:not(:first-child) {
                                border-top: 1px solid hsla(0, 0%, 100%, .1);
                            }

                            .name {
                                @include text-overflow(1);
                                font-size: 13px;
                                line-height: 18px;
                            }

                            .info {
                                margin-top: 10px;
                                font-size: 13px;
                                line-height: 17px;
                                color: #a1a4b3;

                                .is-playing-tips {
                                    padding-left: 20px;
                                    color: $sr-main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .is-playing-color {
        color: $sr-main-color;
    }
}
</style>