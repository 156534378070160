<template>
    <div class="teacher-info">
        <div class="title">
            讲师介绍
        </div>
        <!--讲师卡片-->
        <el-card>
            <div class="teacher-card">
                <div>
                    <el-avatar :size="80"
                               :src="item.imgUrl"/>
                </div>
                <div style="margin-left: 15px; margin-bottom: 10px;">
                    <h4>{{ item.name }}</h4>
                    <div class="desc">
                        {{ item.teacherDesc }}
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "teacherCard",
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>

<style scoped lang="scss">

.teacher-info {
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;

    .title {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        color: #333;
    }

    .teacher-card {
        border-radius: 8px;
        display: flex;
        align-items: center;

        .desc {
            font-size: 12px;
            color: #666;
            line-height: 18px;
        }
    }
}

</style>